import React, { useContext } from "react";

import Logo from "../img/logo/logo5.png";
import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

function Intro() {
  const { text } = useContext(LanguageContext);
  const { introPos } = useContext(PosContext);

  return (
    <>
      <div ref={introPos} className=" introBg">
        <div className="introContainer bgWhiteTransparent">
          <div className="introSection">
            <div className="flexCol">
              <img src={Logo} className="logoIntro" />
            </div>
            <div className="mainTextsIntro">
              <div className="mainDescr0 fontAldrich font4">
                <span className="firstLetterMainDescr">P</span>
                <span className="secondLetterMainDescr">
                  ower, step by step
                </span>
              </div>
              <div className="mainDescr1 fontOrbitron font5">
                {text.mainDescr1}
              </div>
            </div>
            <div className="mainDescr2 fontRationale font6 blurBoxBlackEffect">
              <div className="textMainDescr2">{text.mainDescr2}</div>
              <div className="textMainDescr2">{text.mainDescr3}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Intro;

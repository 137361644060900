import React, { useState, useEffect, createContext } from "react";
import { EN, FR } from "../data/language";

export const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("EN");
  const [text, setText] = useState(EN);

  useEffect(() => {
    if (language === "EN") {
      setText(EN);
    } else {
      setText(FR);
    }

    return () => {};
  }, [language]);

  return (
    <LanguageContext.Provider value={{ language, text, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageProvider;

import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

function LanguageSelector() {
  const { language, setLanguage } = useContext(LanguageContext);

  function toggleLanguage(selectedlanguage) {
    setLanguage(selectedlanguage);
    document.querySelectorAll("details").forEach((D) => {
      if (D.open) {
        D.open = false;
      }
    });
  }

  return (
    <details className="headerLanguage font3">
      {language === "EN" && (
        <>
          <summary>
            <i className="em em-gb flag"></i>
          </summary>
          <div className="headerLanguage2">
            <i
              onClick={() => toggleLanguage("FR")}
              className="em em-fr flag secondFlag"
            ></i>
          </div>
        </>
      )}
      {language === "FR" && (
        <>
          <summary>
            <i className="em em-fr flag"></i>
          </summary>
          <div className="headerLanguage2">
            <i
              onClick={() => toggleLanguage("EN")}
              className="em em-gb flag secondFlag"
            ></i>
          </div>
        </>
      )}
    </details>
  );
}

export default LanguageSelector;

import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";

import { ImLinkedin } from "react-icons/im";

function Footer() {
  const { text } = useContext(LanguageContext);

  return (
    <div className="footerContainer blurBgBlackEffect2">
      {/* <hr className="upperLineFooter" /> */}
      <div className="footerTexts footerTextsColor">
        <div>
          {text.copyrightText1} {text.copyrightText2}
        </div>
        <div className="sirenContent">
          {text.sirenText}
          {/* <div>{text.sirenText}</div> */}
          <a
            href="https://www.linkedin.com/"
            target="_blank"
            className="flexRow footerTextsColor"
          >
            <ImLinkedin size={22} />
          </a>
        </div>
        {/* <div className="devCredits">Made by a God.</div> */}
      </div>
    </div>
  );
}

export default Footer;

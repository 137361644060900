import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import LanguageProvider from "./context/LanguageContext";
import PosProvider from "./context/PosContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LanguageProvider>
    <PosProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PosProvider>
  </LanguageProvider>
);

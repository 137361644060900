import React from "react";
import Intro from "./Intro";
import Services from "./Services";
import Header from "../components/Header";
import OthersActivities from "./OthersActivities";
import Contact from "./Contact";
import Footer from "../components/Footer";
import About from "./About";

function Website() {
  return (
    // <div className="websiteContainer">
    <>
      {/* <Header /> */}
      <Intro />
      <Services />
      <OthersActivities />
      <About />
      <Contact />
      <Footer />
    </>
    // </div>
  );
}

export default Website;

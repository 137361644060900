export var EN = {
  servicesLink: "Services",
  portfolioLink: "Portfolio",
  aboutLink: "About us",
  contactLink: "Contact",
  servicesTitle: "Our services",
  portfolioTitle: "Our projects",
  aboutTitle: "About us",
  contactTitle: "Contact",
  projectSubTitle: "Project specification",
  documentationSubTitle: "Technical documentation",
  simulationSubTitle: "Simulations and optimizations",
  routageSubTitle: "Schematic & Routing",
  prototypageSubTitle: "Prototyping",
  otherActivitiesSubTitle: "Other activities",
  formationSubTitle: "Formation",
  supportSubTitle: "Mechanical support",
  mainDescr1: "A design adapted to your needs",
  mainDescr2:
    "Founded in 2023, powcoma is specialized in electronic engineering for constrained fields such as space and aeronautics.",
  mainDescr3:
    "Our skills cover power conversion and analog, as well as project support from specification to documentation.",
  servicesDescr: "Want to know more ? Check our portfolio : ",
  portfolioDescr: "",
  aboutDescrTop:
    "Powcoma is the story of two engineers but above all friends who decided to create their company specializing in electronics to provide their expertise and know-how. A little tour to get to know us better :",
  aboutDescrBottom:
    "The shared experience at 3D PLUS helped develop valuable skills in analog and Mix design. The design of space products requires following many design rules and taking into account many external factors, such as radiative environments or significant temperature variations. These experiences acquired over more than 7 years alongside experts in the field have constituted a solid background for designing complex products with high added value and associating them with rigorous and complete supporting documentation (such as test plans, Part-Stress/Worst-case justification or architecture reports). Our similar but especially complementary profiles allow us to respond to numerous requests for design and technical support, on low and high power.",
  contactDescr: "",
  projectDescr1:
    "Powcoma ensures that your specifications or needs are translated into a tailor-made technical specification.",
  projectDescr2:
    "Powcoma could also makes an evolution of an existing design. Moreover, design expertise of on-going design is possible.",
  documentationDescr:
    "We support you in writing the documentation associated with a project. We address both the project side (Gantt, milestone documentation) and the technical side. In the last one, we cover the whole range from architecture studies to design justification (DJD), as well as worst-case analyses (WCA) in line with applicable standards (ECSS).",
  simulationDescr:
    "What distinguishes us is our innovative approach to simulation. We use optimization tools coupled with simulation to find the configuration best suited to your needs (space, performance, thermal, etc.). Our tools cover high-level system studies (Verilog/VerilogA for numerical control), simulations with models of real components (SPICE), and physical studies (finite elements, magnetic/thermal). Knowledge of the system as a whole enables us to accurately predict the behavior of a complex design.",
  routageDescr:
    "We handle the entire process, from schematic design to prototype assembly. Our skills in complex technologies (GaN, Planar magnetics, Multi-board) enable us to produce compact, high-performance, innovative boards. The design of these boards complies with current design standards (ECSS).",
  prototypageDescr:
    "The size of our structure and our agility enable us to iterate rapidly during the prototyping phase, facilitating debugging and characterization. We have automated test facilities for rapid, in-depth characterization. Our tests cover temperature performance validation, EMC validation and thermal model validation. We are able to produce from single prototypes to small series (a few hundred parts).",
  prototypageItem1: "Electrical validation",
  prototypageItem2: "Validation of performances over full temperature range",
  prototypageItem3: "EMC approval",
  formationDescr:
    "We offer different types of training, from the study of power conversion topologies (structures, planar components, large gap components) to the consideration of design rules for space (part-stress, worst-case analysis).",
  supportDescr:
    "We can contact subcontractors and co-design the mechanical part of your project with them.",
  CCDescr1:
    "I'm Cédric Colonna, an engineer with a PhD in power conversion. Passionate about technological innovation and its practical applications, I co-founded Powcoma with my best friend, to provide innovative solutions in the field of power conversion applied to space. With over 5 years' experience in research and development, I've been able to familiarize myself with the worlds of industry and research to get the best out of both. As research and industry are closely linked, it's important for me to regularly contribute to the development of this community through publications and patents. In this sense, I'm convinced that we can contribute to advancing the field through our appetite for solving complex problems and our great flexibility.",
  CCDescr2:
    "At Powcoma, we're determined to push back the boundaries of technological innovation to create a better future.",
  FMDescr1:
    "Graduated at the top of the Embedded Electronics class at CentraleSupélec engineering school (formerly Supélec), I joined 3D PLUS to enhance my technical skills in analog electronics within the aerospace field. After designing several projects, I had the opportunity to lead a team of engineers for over 2 years. In order to work on larger-scale projects and develop additional skills such as project management and customer relations, I joined ArianeGroup. There, I played a key role in developing the entire power chain of the reusable launcher Thémis. All these experiences led me to co-found POWCOMA with Cédric. Together, leveraging our profiles that are both similar and complementary, we aim to create innovative products while adhering to the rigorous documentation standards of the aerospace industry. We strongly believe that our adaptability to various projects can be an asset in numerous fields, including aerospace.",
  FMDescr2:
    "At POWCOMA, we use our diverse experiences to deliver innovative solutions which comes with a rigorous documentary package.",
  namePlaceholder: "Your name",
  mailPlaceholder: "Your email address",
  messagePlaceholder: "Your message",
  sendButtonText: "Send",
  enterValidMail: "Please enter a valid address mail",
  sendedMessage: "Message sended !",
  copyrightText1: "Copyright © 2023 Powcoma®.",
  copyrightText2: "All rights reserved.",
  sirenText:
    "Powcoma SAS 953422219 RCS Grenoble with share capital of 2000 euros.",
};

export var FR = {
  servicesLink: "Services",
  portfolioLink: "Portfolio",
  aboutLink: "À propos",
  contactLink: "Contact",
  servicesTitle: "Nos services",
  portfolioTitle: "Nos projets",
  aboutTitle: "À propos",
  contactTitle: "Contact",
  projectSubTitle: "Spécifications projet",
  documentationSubTitle: "Documentation technique",
  simulationSubTitle: "Simulations et optimisations",
  routageSubTitle: "Schematic & Routing",
  prototypageSubTitle: "Prototypage",
  otherActivitiesSubTitle: "Autres activités",
  formationSubTitle: "Formation",
  supportSubTitle: "Suivi d'étude mécanique",
  mainDescr1: "Une conception adaptée à vos besoins",
  mainDescr2:
    "Créée en 2023, la société Powcoma est spécialisée dans l'ingénierie électronique pour des domaines contraints comme le spatial ou l'aéronautique. Nos compétences couvrent la conversion de puissance, l'analogique mais aussi l'accompagnement projet de la spécification à la rédaction documentaire.",
  // mainDescr3:
  //   "Nos compétences couvrent la conversion de puissance, l'analogique mais aussi l'accompagnement projet de la spécification à la rédaction documentaire. ",
  servicesDescr: "Envie d'en savoir plus ? Consultez notre portfolio : ",
  portfolioDescr: "",
  aboutDescrTop:
    "C'est l'histoire de deux ingénieurs et avant tout amis qui ont décidé de créer leur entreprise spécialisée dans l'électronique pour apporter leur expertise et leur savoir-faire. Petit tour de table pour mieux nous connaitre :",
  aboutDescrBottom:
    "L'expérience commune acquise chez 3D PLUS a permis de développer des compétences précieuses dans le design analogique et mixte. La conception de produits spatiaux requiert de suivre de nombreuses règles de conception et de prendre en compte de nombreux facteurs externes, comme les environnements radiatifs ou des variations de température importantes. Ces expériences acquises pendant plus de 7 ans aux côté d'experts du domaine ont constitué un bagage solide pour concevoir des produits complexes à forte valeur ajoutée et pour y associer de la documentation justificative rigoureuse et complète (comme des plans de tests, des dossiers de justification Part-Stress/Worst-case ou des rapports d'architecture). Nos profils à la fois similaires mais surtout complémentaires nous permettent de pouvoir répondre à de nombreuses demandes de design comme de support technique, sur de la basse et haute puissance.",
  contactDescr: "",
  projectDescr1:
    "Powcoma s'assure de traduire votre cahier des charges ou votre besoin en une spécification technique sur mesure.",
  projectDescr2:
    "La modification Hardware d'un projet électronique existant est également réalisable.",
  documentationDescr:
    "Nous vous accompagnons dans la rédaction de la documentation associée à un projet. Nous adressons autant la partie projet (Gantt, documentation jalon) que la partie technique. Dans cette dernière nous couvrons toute la gamme de l'étude d'architecture à la justification du design (DJD) en passant par des analyses pire cas (WCA) en accord avec les normes en application (ECSS).",
  simulationDescr:
    "Nous nous différencions par rapport à notre approche novatrice en matière de simulation. Nous utilisons des outils d'optimisation couplés à de la simulation pour trouver la configuration la plus adaptée aux besoins (place, performances, thermique..). Nos outils couvrent les études systèmes haut niveau (Verilog/VerilogA pour le contrôle numérique), les simulations avec modèles de composants réels (SPICE), et les études physiques (éléments finis, magnétiques/thermiques). La connaissance du système dans sa globalité nous permet de prédire avec précision le comportement d'un système complexe.",
  routageDescr:
    "Nous couvrons toute la procédure qui s'étend de la saisie du schéma à l'assemblage du prototype. Notre maîtrise en technologies complexes (GaN, Magnetiques planars, Multi-board), nous permettent de réaliser des cartes compactes, performantes et innovantes. La conception de ces cartes respectent les normes de conception en vigueur (ECSS).",
  prototypageDescr:
    "La taille de notre structure et notre agilité nous permettent une itération rapide pendant la phase de prototypage, facilitant le débug et la caractérisation. Nous disposons de moyens de test automatisés permettant une caractérisation rapide et poussée. Nos tests couvrent la validation des performances en température, la validation CEM, la validation des modèles thermiques. Nous sommes en capacité de produire du prototype unique à la petite série (quelques centaines de pièces).",
  prototypageItem1: "Validation électrique du prototype",
  prototypageItem2: "Validation des performances en température",
  prototypageItem3: "Validation CEM",
  formationDescr:
    "Nous proposons différents types de formation allant de l'étude des topologies de conversion de puissance (structures, composants planar, composants grand gap) à la prise en compte des règles de design pour le spatial (Part-stress, analyse pire cas).",
  supportDescr:
    "Nous nous chargeons de contacter des sous-traitants et de co-concevoir avec eux la partie mécanique de votre projet.",
  CCDescr1:
    "Je suis Cédric Colonna, ingénieur titulaire d'un doctorat en conversion de puissance. Passionné par l'innovation technologique et ses applications pratiques, j'ai co-fondé avec mon meilleur ami, Powcoma pour apporter des solutions novatrices dans le domaine de la conversion d'énergie appliquée au spatial. Avec plus de 5 années d'expérience dans le domaine de la recherche et du développement, j'ai pu me familiariser avec le monde de l'entreprise et celui de la recherche pour en tirer le meilleur des deux. La recherche et l'industrie étant étroitement liées, il est important pour moi de participer régulièrement au développement de cette communauté à travers des publications et des brevets. En ce sens, je suis convaincu que nous pouvons contribuer à faire avancer le domaine par notre appétence dans la résolution de problèmes complexes et notre grande flexibilité.",
  CCDescr2:
    "Chez Powcoma, nous sommes déterminés à repousser les limites de l'innovation technologique pour créer un avenir meilleur.",
  FMDescr1:
    "Diplômé major de la promotion Electronique embarqué à l'école d'ingénieur CentraleSupélec (ex Supélec), j'ai intégré 3D PLUS pour développer mes compétences techniques en électronique analogique dans le domaine du spatial. Après avoir conçu plusieurs projets, j'ai eu l'opportunité de manager durant plus de 2 ans une équipe composée d'ingénieurs. Afin de développer des produits de plus grosses envergures et développer d'autres compétences  tel que la gestion projet ou encore le suivi clientèle, j'ai rejoint ArianeGroup où j'ai pu notamment développer toute la chaine power du lanceur réutilisable Thémis. Toutes ces expériences m'ont emmené à fonder POWCOMA avec Cédric pour créer, avec nos profils à la fois similaires et complémentaires, des produits innovants en respectant la rigueur documentaire du domaine spatial. Nous sommes convaincus que notre adaptabilité à tous types de projet peut être un atout dans de nombreux domaines dont celui du spatial.",
  FMDescr2:
    "Chez POWCOMA, nous mettons à profil nos expériences variées pour vous apporter des solutions novatrices avec la rigueur documentaire associée.",
  namePlaceholder: "Votre nom",
  mailPlaceholder: "Votre adresse email",
  messagePlaceholder: "Votre message",
  sendButtonText: "Envoyer",
  enterValidMail: "Entrez un email valide s'il vous plait",
  sendedMessage: "Message envoyé !",
  copyrightText1: "Copyright © 2023 Powcoma®.",
  copyrightText2: "All rights reserved.",
  sirenText:
    "Powcoma SAS 953422219 RCS Grenoble au capital social de 2000 euros.",
};

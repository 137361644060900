import React, { useContext } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

function Contact() {
  const { text } = useContext(LanguageContext);
  const { contactPos } = useContext(PosContext);

  return (
    <div ref={contactPos} className=" flexCol contactContainer">
      <div className="contactBox">
        <div className="titleContact fontEthnocentric font4 greenText">
          {text.contactTitle}
        </div>
        <div className="flexCol contactForm">
          {/* <div className="upContactForm"></div> */}
          <div className="nameAndTextContactInputs">
            <input
              type="text"
              placeholder={text.namePlaceholder}
              className="contactName inputContact fontAudiowide"
            ></input>
            <input
              type="email"
              placeholder={text.mailPlaceholder}
              className="contactEmail inputContact fontAudiowide"
            ></input>
          </div>
          <textarea
            type="text"
            placeholder={text.messagePlaceholder}
            className="contactMessage inputContact fontAudiowide"
          ></textarea>
        </div>
        <div className="sendContactBtn fontOrbitron">{text.sendButtonText}</div>
      </div>
    </div>
  );
}

export default Contact;

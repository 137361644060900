import React, { useContext } from "react";

import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

import { ImLinkedin } from "react-icons/im";

import CCImg from "../img/about/cc05.png";
import FMImg from "../img/about/fm04-bis.png";

function About() {
  const { text } = useContext(LanguageContext);
  const { aboutPos } = useContext(PosContext);

  return (
    <div ref={aboutPos} className="aboutPageContainer">
      <div className="aboutTitle fontEthnocentric font4 greenText">
        {text.aboutTitle}
      </div>
      <div className="aboutDescr">{text.aboutDescrTop}</div>
      {/* <div className="aboutBoxes"> */}
      <div className="aboutContainer">
        <img src={CCImg} alt="" className="aboutImg" />
        <div className="flexCol aboutBox aboutBoxCC ">
          <img src={CCImg} alt="" className="aboutImgSmall" />
          <div className="aboutBoxTitle ccTitle font8 fontAudiowide">
            <div>
              Cédric Colonna{" "}
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                className="textColorWhite"
              >
                <ImLinkedin size={22} />
              </a>
            </div>
          </div>
          <div className="aboutBoxDescr">
            <div className="aboutQuote">‟</div>
            <div className="aboutBoxText">
              <div>{text.CCDescr1}</div>
              <div style={{ fontStyle: "italic" }}>{text.CCDescr2}</div>
            </div>
            <div className="aboutQuote">”</div>
          </div>
        </div>
      </div>
      <div className="aboutContainer">
        <div className="flexCol aboutBox aboutBoxFM">
          <img src={FMImg} alt="" className="aboutImgSmall" />
          <div className="aboutBoxTitle fmTitle font8 fontAudiowide">
            <div>
              <a
                href="https://www.linkedin.com/"
                target="_blank"
                className="textColorWhite"
              >
                <ImLinkedin size={22} />
              </a>{" "}
              François Marconcini
            </div>
          </div>
          <div className="aboutBoxDescr">
            <div className="aboutQuote">‟</div>
            <div className="aboutBoxText">
              <div>{text.FMDescr1}</div>
              <div style={{ fontStyle: "italic" }}>{text.FMDescr2}</div>
            </div>
            <div className="aboutQuote">”</div>
          </div>
        </div>
        <img src={FMImg} alt="" className="aboutImg" />
      </div>
      {/* </div> */}
    </div>
  );
}

export default About;

import React from "react";
import { useContext } from "react";
import { VerticalTimeline } from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
import ServiceContentRight from "../components/ServiceContentRight";
import ServiceContentLeft from "../components/ServiceContentLeft";

import { LanguageContext } from "../context/LanguageContext";
import { PosContext } from "../context/PosContext";

import ProjetImg from "../img/services/projet2.png";
import { RiPencilFill } from "react-icons/ri";

import DocumentationImg from "../img/services/documentation5.png";
import { HiDocumentText } from "react-icons/hi";

import SimulationImg from "../img/services/simulation3.png";
import { RiComputerFill } from "react-icons/ri";

import RoutageImg from "../img/services/routage3.png";
import { PiCircuitryFill } from "react-icons/pi";

import PrototypageImg2 from "../img/services/prototypage4.png";
import { FaMicrochip } from "react-icons/fa6";

function Services() {
  const { text } = useContext(LanguageContext);
  const { servicesPos } = useContext(PosContext);

  return (
    <>
      <div ref={servicesPos} className="servicesSection servicesBg">
        <div className="servicesTitle fontEthnocentric font4 greenText blurEffectBg">
          {text.servicesTitle}
        </div>
        <div className="servicesContainer">
          <VerticalTimeline animate={false} lineColor={"black"}>
            <ServiceContentRight
              title={text.projectSubTitle}
              content={text.projectDescr1}
              content2={text.projectDescr2}
              img={ProjetImg}
              icon={<RiPencilFill />}
            />
            <ServiceContentLeft
              title={text.documentationSubTitle}
              content={text.documentationDescr}
              img={DocumentationImg}
              icon={<HiDocumentText />}
            />
            <ServiceContentRight
              title={text.simulationSubTitle}
              content={text.simulationDescr}
              img={SimulationImg}
              icon={<RiComputerFill />}
            />
            <ServiceContentLeft
              title={text.routageSubTitle}
              content={text.routageDescr}
              img={RoutageImg}
              icon={<PiCircuitryFill />}
            />
            <ServiceContentRight
              title={text.prototypageSubTitle}
              content={text.prototypageDescr}
              img={PrototypageImg2}
              icon={<FaMicrochip />}
            />
          </VerticalTimeline>
        </div>
      </div>
    </>
  );
}

export default Services;
